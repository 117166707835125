import "../styles/custom-antd.css";
import "../styles/globals.scss";
import "react-image-lightbox/style.css";
import "../components/header/Header.module.scss";

import Head from "next/head";

import { useMemo } from "react";

import * as Sentry from "@sentry/node";
import { RewriteFrames } from "@sentry/integrations";
import getConfig from "next/config";

import Router from "next/router";
import TagManager from "grandus-lib/utils/gtag";
import FBPixel from "grandus-lib/utils/fbpixel";

import InfoBox from "components/InfoBox";
import Header from "components/header/Header";
import Footer from "components/footer/Footer";

// import dynamic from "next/dynamic";
// const CookieConsent = dynamic(
//   () => import("components/_others/cookieConsent"),
//   { ssr: false }
// );

import NProgress from "nprogress";
Router.events.on("routeChangeStart", () => NProgress.start());
Router.events.on("routeChangeComplete", () => NProgress.done());
Router.events.on("routeChangeError", () => NProgress.done());

// export function reportWebVitals(metric) {
//   if (metric.label === "custom") {
//     console.info(`⏱️ | ${metric.name} - ${metric.value}ms`);
//   }
// }

if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
  const config = getConfig();
  const distDir = `${config.serverRuntimeConfig.rootDir}/.next`;
  Sentry.init({
    enabled: process.env.NODE_ENV === "production",
    integrations: [
      new RewriteFrames({
        iteratee: (frame) => {
          frame.filename = frame.filename.replace(distDir, "app:///_next");
          return frame;
        },
      }),
    ],
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  });
}

const scrollCallback =
  (fixedWidget, widgetFixedMegaMenuItems = []) =>
  () => {
    setTimeout(() => {
      if (window.scrollY > fixedWidget.offsetTop + fixedWidget.offsetHeight) {
        fixedWidget.classList.add("visible");
      } else {
        fixedWidget.classList.remove("visible");

        if (widgetFixedMegaMenuItems.length) {
          widgetFixedMegaMenuItems.forEach((item) => {
            if (item.classList.contains("open")) item.classList.remove("open");
          });
        }
      }
    }, 250);
  };

const GrandusApp = ({ Component, pageProps }) => {
  React.useEffect(() => {
    TagManager.registerPageViewTracking(Router);
    FBPixel.registerPageViewTracking(Router);
  }, []);

  React.useEffect(() => {
    if (typeof window !== "undefined") {
      let widgetFixedMegaMenuItems =
        document.querySelectorAll(".jsHasMegaMenu");

      if (widgetFixedMegaMenuItems.length) {
        widgetFixedMegaMenuItems.forEach((item) => {
          item.addEventListener("click", function () {
            if (this.classList.contains("open")) this.classList.remove("open");
            else this.classList.add("open");
          });
        });
      }

      let scrollTopEl = document.getElementById("scrollTop");

      if (typeof scrollTopEl != "undefined" && scrollTopEl != null) {
        window.addEventListener("scroll", function () {
          if (window.scrollY > 700) scrollTopEl.classList.add("visible");
          else scrollTopEl.classList.remove("visible");
        });

        scrollTopEl.addEventListener("click", () => {
          scrollToTop(1000);
        });
      }

      let fixedWidget = document.getElementById("categoryWidgetFixed");

      if (typeof fixedWidget != "undefined" && fixedWidget != null) {
        window.addEventListener(
          "scroll",
          scrollCallback(fixedWidget, widgetFixedMegaMenuItems)
        );
      }
    }
    return () => {
      window.removeEventListener("scroll", scrollCallback);
    };
  });

  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no"
        />
        <meta name="apple-mobile-web-app-title" content="bigon.sk" />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#1ff000" />
        <meta name="msapplication-TileColor" content="#000000" />
        <meta name="theme-color" content="#ffffff" />
        <link
          rel="apple-touch-icon"
          sizes="57x57"
          href="/apple-icon-57x57.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="60x60"
          href="/apple-icon-60x60.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="72x72"
          href="/apple-icon-72x72.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="76x76"
          href="/apple-icon-76x76.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="114x114"
          href="/apple-icon-114x114.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="120x120"
          href="/apple-icon-120x120.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="144x144"
          href="/apple-icon-144x144.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="152x152"
          href="/apple-icon-152x152.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-icon-180x180.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="192x192"
          href="/android-icon-192x192.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="96x96"
          href="/favicon-96x96.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link rel="manifest" href="/manifest.json" />
        <meta name="msapplication-TileColor" content="#000000" />
        <meta name="msapplication-TileImage" content="/ms-icon-144x144.png" />
        <meta name="theme-color" content="#000000" />
        <meta
          name="facebook-domain-verification"
          content="k6t86i9boluye3y2921856cdsmyh9c"
        />
        <meta
          name="google-site-verification"
          content="u1rTnT0kR3A7wSE4ZEyxssFOkpkQ6lbVsklRRoQM-Lw"
        />
        <script
          async
          src="https://scripts.luigisbox.com/LBX-237841.js"
        ></script>
      </Head>
      {useMemo(
        () => (
          <InfoBox />
        ),
        []
      )}
      {useMemo(
        () => (
          <Header />
        ),
        []
      )}
      <Component {...pageProps} />
      {useMemo(
        () => (
          <Footer />
        ),
        []
      )}
      {/* <CookieConsent /> */}
    </>
  );
};

// Only uncomment this method if you have blocking data requirements for
// every single page in your application. This disables the ability to
// perform automatic static optimization, causing every page in your app to
// be server-side rendered.
//
// MyApp.getInitialProps = async (appContext) => {
//   // calls page's `getInitialProps` and fills `appProps.pageProps`
//   const appProps = await App.getInitialProps(appContext);
//
//   return { ...appProps }
// }

export default GrandusApp;
