import * as yup from "yup";
import { Form, message } from "antd";
import { useFormikContext, Formik } from "formik";
import { get } from "lodash";

import TextInput from "grandus-lib/components-atomic/form/TextInput";
import styles from "./Newsletter.module.scss";
import SalesManago from "utils/salesmanago";

const styles2 = {};

const Newsletter = () => {
  const formProps = {
    enableReinitialize: true,
    initialValues: {
      email: "",
      privacyPolicy: true,
    },
    validationSchema: yup.object({
      email: yup
        .string()
        .nullable()
        .email("Zadajte email vo formáte name@domain.com")
        .required("Zadajte vašu emailovú adresu"),
      privacyPolicy: yup
        .bool()
        .oneOf([true], "Musíte súhlasiť so spracovaním osobných údajov"),
    }),
    onSubmit: async (values, { setFieldError, setErrors, resetForm }) => {
      const { email } = values;
      const reqBody = { email: email };
      try {
        const response = await fetch(`/api/lib/v1/newsletter/subscribe`, {
          method: "POST",
          body: JSON.stringify(reqBody),
        }).then((data) => data.json());

        if (response) {
          const errorMessage = get(get(response, "messages.0"), "message");
          if (!errorMessage) {
            resetForm();
            message.success(
              "Boli ste úspešne prihlásený na odber newslettra",
              5
            );
            SalesManago.contactUpsert({ email: email, agreementAccepted: true });
          } else {
            setFieldError("email", errorMessage);
            message.error(errorMessage, 5);
          }
        }
      } catch (error) {
        console.error("An unexpected error happened:", error);
        setErrors(error.data.message);
      }

      return false;
    },
  };

  return (
    <Formik {...formProps}>
      <NewsletterForm />
    </Formik>
  );
};

const NewsletterForm = () => {
  const {
    values,
    errors,
    touched,
    isSubmitting,
    isValid,
    dirty,
    handleChange,
    handleBlur,
    handleSubmit,
  } = useFormikContext();
  return (
    <Form onFinish={handleSubmit} className={styles.newsletter}>
      <TextInput
        label="Email"
        name="email"
        values={values}
        touched={touched}
        errors={errors}
        handleChange={handleChange}
        handleBlur={handleBlur}
      />
      <Form.Item>
        <button
          className={[styles2.btn, "primary"].join(" ")}
          type="submit"
          // htmlType="submit"
          // loading={isValid && isSubmitting}
          // disabled={!dirty || !isValid}
        >
          {isSubmitting ? "Pracujem..." : "Odoberať"}
        </button>
      </Form.Item>
      {/* <Form.Item
        valuePropName="checked"
        // className={styles?.agreementCheckboxWrapper}
        name="privacyPolicy"
        validateStatus={
          touched?.privacyPolicy && errors?.privacyPolicy ? "error" : ""
        }
        help={
          touched?.privacyPolicy && errors?.privacyPolicy
            ? errors?.privacyPolicy
            : ""
        }
      >
        <Checkbox onChange={handleChange}>
          Súhlasím so spracovaním osobných údajov
        </Checkbox>
      </Form.Item> */}
    </Form>
  );
};

export default Newsletter;
